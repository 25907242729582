@import "../../styles/variables";

.valueProps {
    padding-top: 60px;
    padding-bottom: 60px;
    background-color: $pewter1;

    @media ($below-mobile) {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    li {
        display: flex;
        justify-content: center;

        @media ($above-mobile) {
            flex: 1;
            flex-shrink: 0;
            max-width: 300px;
            gap: 30px;
            text-align: center;
        }

        @media ($below-mobile) {
            width: 100%;
            gap: 10px;
        }
    }
}

.propsList {
    composes: container from "../../styles/layout.module.scss";
    composes: listReset from "../../styles/utility.module.scss";
    display: flex;
    justify-content: center;
    gap: 20px;

    @media ($below-mobile) {
        flex-direction: column;
    }
}

.valueProp {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    text-decoration: none;

    @media ($below-mobile) {
        width: 100%;
        max-width: 400px;
        flex-direction: row;
        gap: 20px;
        font-size: 14px;

        span {
            flex: 1;
        }
    }

    img,
    svg {
        width: 100px;
        height: 100px;
        flex-shrink: 0;

        @media ($below-mobile) {
            width: 80px;
            height: 80px;
        }
    }
}

.text {
    font-family: var(--value-props--text-font, #{$secondary-font-family});
    font-weight: 400;
    letter-spacing: 0.07em;
    line-height: 1.4em;
    text-transform: uppercase;
}
