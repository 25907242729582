@import "../../styles/variables";

.heroCarousel {
    position: relative;

    @media ($below-mobile) {
        border-bottom: 1px solid $pewter1;
    }
}

.button {
    top: 50%;
}

.buttonPrevious {
    composes: button;
    left: 0;
    transform: translateY(-50%);
}

.buttonNext {
    composes: button;
    right: 0;
    transform: translateY(-50%);
}

.dotGroup {
    display: flex;
    gap: 10px;
    justify-content: center;
    position: absolute;
    bottom: 0.5em;
    left: 0;
    width: 100%;
    margin-top: 10px;

    @media ($below-mobile) {
        bottom: 1em;
    }

    button {
        width: 24px;
        height: 24px;
        background-color: $smoke;
        background: linear-gradient(
            0deg,
            rgba($white, 0) 45.83%,
            rgba($smoke, 1) 45.83%,
            rgba($smoke, 1) 54.17%,
            rgba($white, 0) 54.17%
        );
        opacity: 0.3;
        border: 0;

        &[disabled] {
            opacity: 1;
        }
    }
}
