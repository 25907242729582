// Shared Colors
$black: var(--color-black);
$red: var(--color-red);

// Emtek Colors
$white: #ffffff;
$pewter1: #e6e9eb;
$pewter2: #b9bdc1;
$pewter3: #697278;
$satin-brass: #dbc598;
$satin-brass-hover: #e2d1ad;
$smoke: #616f6c;
$navy: #1a4456;

// Schaub Colors
$gold: #8c734c;
$gold-hover: #a38f70;
$gold-disabled: #d1c7b7;
$green: #6b7261;
$blue: #616e81;
$pink: #1a4456;
$beige: #d3cec2;
$darkSilver: #6d706e;
$silver: #b6bbb7;

// Breakpoints
$smallest: 360px;
$tiny: 480px;
$mobile: 768px;
$tablet: 1024px;
$laptop: 1440px;
$desktop: 1920px;

// Font stuff
$base-font-family: var(--font-base), "sans-serif";
$secondary-font-family: var(--font-secondary), sans-serif;

// Inspired by https://wptips.dev/better-sass-media-query/
$below-smallest: "max-width: #{$smallest - 1}";

$below-tiny: "max-width: #{$tiny - 1}";
$above-tiny: "min-width: #{$tiny}";

$below-mobile: "max-width: #{$mobile - 1}";
$above-mobile: "min-width: #{$mobile}";

$below-tablet: "max-width: #{$tablet - 1}";
$above-tablet: "min-width: #{$tablet}";

$below-laptop: "max-width: #{$laptop - 1}";
$above-laptop: "min-width: #{$laptop}";

$below-desktop: "max-width: #{$desktop - 1}";
$above-desktop: "min-width: #{$desktop}";

$portrait: "orientation: portrait";
$landscape: "orientation: landscape";

// $dark-mode: "prefers-color-scheme: dark";
// To disable dark mode styling, change the query to something nonsensical
$dark-mode: "foo: bar";

$mobile-nav-height: 50px;

$container-width: 1360px;
$container-margin-desktop: 60px;
$container-margin-tablet: 30px;
$container-margin-mobile: 15px;

// Creates a container with a max-width that centers. The max() stuff ensures
// that the margin is always a certain fixed amount, but allows it to get bigger
@mixin container {
    --container-width: #{$container-width};
    --container-min-margin: #{$container-margin-desktop};
    max-width: var(--container-width);
    --full-width-minus-width: calc(100vw - var(--container-width));
    --container-margin: max(
        calc(var(--full-width-minus-width) / 2),
        var(--container-min-margin)
    );
    --current-width: calc(100vw - (var(--container-margin) * 2));
    margin-left: var(--container-margin);
    margin-right: var(--container-margin);

    @media ($below-tablet) {
        --container-min-margin: #{$container-margin-tablet};
    }

    @media ($below-mobile) {
        --container-min-margin: #{$container-margin-mobile};
    }
}

@mixin textinput {
    // Values for height and padding moved to globals.scss
    width: 100%;
    height: var(--text-input-height);
    border: 1px solid var(--text-input-border-color);
    border-radius: 0;
    padding: 0 var(--text-input-padding);
    background-color: $white;
    color: $black;
    font-family: var(--text-input--font-family, inherit);
    font-size: 1em;
    opacity: 1;
    transition:
        border-color 0.2s,
        opacity 0.2s;

    &:focus-within {
        outline: none;
        border-color: $black;
    }

    &::placeholder {
        color: $pewter3;
        opacity: 1;
    }

    &[disabled],
    &[readonly] {
        border-color: transparent;
    }
}

@mixin imageTilesLink {
    display: flex;
    flex-direction: column;
    gap: 15px;
    color: inherit;
    text-decoration: none;
}
